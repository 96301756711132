<template>
  <div>
    <h2 class="mb-4">Produktionsauftrags Import</h2>

    <div class="mb-4">
      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="insertData"
      >
        Produktionsaufträge importieren
      </button>

      <button
        class="text-white bg-primary-700 hover:bg-primary-800 rounded px-4 py-2 text-center mr-2"
        @click="updateData"
      >
        Update Produktionsauftrags Tabelle
      </button>
    </div>

    <dashboard-element>
      <dashboard-head title="Produktionsaufträge" link="/pa" />
      <dashboard-table class="p-3">
        <dashboard-card
          v-for="pa in produktionsauftraege"
          :key="pa.auftragsnr"
          :title="`${pa.auftragsnr}`"
          :content="`${pa.artikelcode} Menge: ${pa.menge} Stück`"
          :link="`/pa/${pa.auftragsnr}`"
          :date="pa.date"
        />
      </dashboard-table>
    </dashboard-element>
  </div>
</template>

<script>
import gql from "graphql-tag";
import DashboardElement from "@/components/dashboard/DashboardElement.vue";
import DashboardHead from "@/components/dashboard/DashboardHead.vue";
import DashboardCard from "@/components/dashboard/DashboardCard.vue";
import DashboardTable from "@/components/dashboard/DashboardTable.vue";
import { supabase } from "../../plugins/supabase";

export const GET_MY_DATA = gql`
  query Produktionsauftraege {
    produktionsauftra_ge(where: { MengeNochOffen: { _gt: "0,0" } }) {
      AuftragsNr
      Artikelcode
      Menge
      MengeNochOffen
      MengeBearbeitet
      zuBelegNr
      zuBelegart
    }
  }
`;

export default {
  components: {
    DashboardElement,
    DashboardHead,
    DashboardCard,
    DashboardTable
  },
  data() {
    return {
      produktionsauftraege: []
    };
  },
  apollo: {
    produktionsauftra_ge: {
      query: GET_MY_DATA,
      error(error) {
        this.error = JSON.stringify(error.message);
      }
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async updateData() {
      this.Produktionsauftraege.map(kunde =>
        supabase.from("produktionsauftraege").upsert([
          {
            customernumber: kunde.KundenNr,
            company: kunde.Firma
          }
        ])
      );
    },
    async insertData() {
      const neue_produktionsauftraege = [];
      this.produktionsauftra_ge.map(pa =>
        neue_produktionsauftraege.push({
          auftragsnr: pa.AuftragsNr,
          artikelcode: pa.Artikelcode,
          menge: parseInt(pa.Menge),
          status: 0,
          belegnr: pa.zuBelegNr
        })
      );

      await supabase
        .from("produktionsauftraege")
        .insert(neue_produktionsauftraege);
    },
    async loadData() {
      let { data: produktionsauftraege } = await supabase
        .from("produktionsauftraege")
        .select("*")
        .range(0, 9);

      this.produktionsauftraege = produktionsauftraege;
    }
  }
};
</script>
